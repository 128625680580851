// 公用api
import request from '@/tools/request';

// 积分详情
export function Detail(data, loading) {
  return request({
    url: `/buypoints-front-PointDetail.html`,
    method: 'get',
    params: data,
    loading,
  });
}
//积分详情_统计
export function PointStatis(data, loading) {
  return request({
    url: `/buypoints-front-PointStatis.html`,
    method: 'get',
    params: data,
    loading,
  });
}
